











































import Vue from "vue";

export default Vue.extend({
  props: {
    readMore: Boolean
  },
  data() {
    return {
      images: [
        // "https://dummyimage.com/800/ffffff/000000",
        // "https://dummyimage.com/1600/ffffff/000000",
        // "https://dummyimage.com/1280/000000/ffffff",
        // "https://dummyimage.com/400/000000/ffffff"
      ]
    };
  }
});
