








import Vue from "vue";
import WhatIamGoodAt from "@/components/Pages/About/WhatIamGoodAt.vue";
import Experience from "@/components/Pages/About/Experience.vue";
import CertificatesAndOther from "@/components/Pages/About/CertificatesAndOther.vue";

export default Vue.extend({
  components: {
    WhatIamGoodAt,
    Experience,
    CertificatesAndOther
  }
});
