



















import Vue from "vue";
import ExperienceSection from "@/components/Pages/About/ExperienceSection.vue";

export default Vue.extend({
  components: {
    ExperienceSection
  },
  props: {
    readMore: Boolean
  },

  data() {
    return {
      educations: [
        {
          start: 2019,
          end: 2021,
          level: "Masters",
          contents: [
            { value: "Working with SQL, R, python", icon: "mdi-code-braces" },
            { value: "Top 5 in 1-st year", icon: "mdi-poll" }
          ]
        },
        {
          start: 2014,
          end: 2018,
          level: "Bachelor",
          contents: [
            {
              value: "Working with C#, PHP, NodeJS, SQL",
              icon: "mdi-code-braces"
            },
            { value: "Top 3-rd in 1-st year", icon: "mdi-poll" },
            { value: "Top 500 Netherlands", icon: "mdi-trophy-award" },
            { value: "Design patterns", icon: "mdi-lock-pattern" },
            { value: "S.O.L.I.D.", icon: "mdi-consolidate" },
            { value: "Graduate with excellency", icon: "mdi-account-star" },
            { value: "Internship Studyportals", icon: "mdi-school" },
            { value: "Internship Studyportals", icon: "mdi-school" },
            {
              value: "Part of Delta Track (working on customer projects)",
              icon: "mdi-school",
              link:
                "https://fontys.edu/Bachelors-masters/Bachelors/Information-Communication-Technology-Eindhoven/Students-about-this-programme/Nikola-Nikushev.htm"
            },
            {
              value:
                "Signed up for additional math & programming courses at TU/e",
              icon: "mdi-school"
            }
          ]
        },
        {
          start: 2012,
          end: 2013,
          level: "Telerik Academy",
          contents: [
            { value: "Working with C#, Javascript", icon: "mdi-code-braces" },
            { value: "Ninja project (coming soon)", icon: "mdi-school" }, //TODO: Get link
            { value: "Clean code", icon: "mdi-monitor-clean" },
            { value: "OOP", icon: "mdi-family-tree" }
          ]
        },
        {
          start: 2013,
          end: 2013,
          level: "Internship: XS Software",
          contents: [
            { value: "Working with C#, Unity", icon: "mdi-code-braces" },
            { value: "3D game graphics" },
            { value: "OOP", icon: "mdi-family-tree" },
            {
              value: "Project: Andromeda 5",
              icon: "mdi-school",
              link: "https://www.facebook.com/andromedafive/"
            }
          ]
        },
        {
          start: 2014,
          end: 2014,
          level: "Internship: Musala soft",
          contents: [
            {
              value: "Working with Java, JavaApplets",
              icon: "mdi-code-braces",
              link: "http://www.musala.com/"
            },
            { value: "OOP", icon: "mdi-family-tree" }
          ]
        }
      ],
      workExperience: [
        {
          start: 2022,
          level: "TourRadar",
          contents: [
            {
              value:
                "Working as a Senior Software Engineer on distribution APIs",
              icon: "mdi-cellphone-link"
            }
          ]
        },
        {
          start: 2020,
          end: 2022,
          level: "Bitmovin",
          contents: [
            {
              value: "Working on cross platform adaptive Video Player",
              icon: "mdi-play-network",
              link: "https://bitmovin.com/docs/player"
            },
            { value: "Tizen, WebOS", icon: "mdi-television-classic" }
          ]
        },
        {
          start: 2018,
          level: "Co-founder friendzone",
          contents: [
            { value: "Team lead & project management", icon: "mdi-file-tree" },
            {
              value: "DevOps infrastructure for Back-end and front-end website",
              icon: "mdi-account-hard-hat",
              link: "https://www.friendzone.at"
            },
            { value: "Back-end Node.JS", icon: "mdi-code-braces" },
            { value: "Front-end Vue.js", icon: "mdi-code-braces" },
            { value: "VueNative mobile app development", icon: "mdi-cellphone" }
          ]
        },
        {
          start: 2020,
          end: 2018,
          level: "TourRadar",
          contents: [
            {
              value: "Working with terraform for back-end API infrastructure",
              icon: "mdi-account-hard-hat"
            },
            {
              value:
                "Building process automation using GO and Serverless Lambdas",
              icon: "mdi-language-go"
            },
            {
              value: "Back-end API with PHP (Laravel & Symfony)",
              icon: "mdi-code-braces"
            },
            {
              value: "Working on mobile & browser pages for website",
              icon: "mdi-cellphone-link",
              link: "https://www.tourradar.com/"
            }
          ]
        },
        {
          start: 2016,
          end: 2018,
          level: "StudyPortals",
          contents: [
            {
              value:
                "Working as develops and automating the website deployment process from 4h to 15min. Allowing daily releases.",
              icon: "mdi-alarm-check"
            },
            {
              value: "Back-end website development with PHP",
              icon: "mdi-language-php",
              link: "https://https://www.mastersportal.com/"
            },
            {
              value: "Back-end API development with lambda & Serverless",
              icon: "mdi-lambda"
            }
          ]
        }
      ]
    };
  }
});
