










































import Vue from "vue";

export default Vue.extend({
  name: "ExperienceSection",
  props: {
    sectionHeader: { type: String },
    experiences: { type: Array }
  }
});
